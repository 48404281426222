import { HttpErrorResponse } from '@angular/common/http';

type StringOrNumber = string | string[] | number | number[];
// Begin replacing our string | string[] with SafariObjectId
// During the transition we will require some casts etc on existing objects that have been
// defined with just string ID. The hope is that at some point we can remove _parentId
// and SafariObjectId will be either a simple string (base object) or an array of strings
// representing parents AND the actual object (currently we have __parentIds , which is a bit annoying
// to work with when creating new objects)

export type SafariObjectId = string | number;
export class SafariObject {
  static EMPTY = {} as any;
  static NOID = '';

  id: SafariObjectId;

  error?: HttpErrorResponse;
  // Below are properties needed for internal functioning of our framework.
  // You shouldn't be modifying these except maybe, in some special circumstances, etag. We might change
  // "__etag" property name to "etag" (since __ prefix usually signifies "do not use")
  // Also maybe we can figure some trick to implement friend-like classes where framework needs these props
  // so that they can be exposed as public internall inside teh framework (keep strongtypedness), but
  // be private in the actual object definition so nobody tries to use them
  __priorId?: SafariObjectId = null; // don't touch this - reserved for internal create calls
  /**@deprecated - do not use. Everything is in the ID now */
  __parentIds?: string[] = null; // to be removed but we need to deal with it in crud.service first
  __etag?: string = null; // usually you don't need to mess with this but sometimes you might need to override
  // might consisder removing leading "__"
  __base?: unknown = null; // don't touch this - reserved for internal 409 auto merge resolution
  /**@deprecated - do not use. Adapters should be enough */
  __onErrorMergeWith?: unknown = null; // to be removed

  static idToArray(arrayOrStringWithCommas: StringOrNumber): string[] {
    // If null/undef/empty was passed in it will return that without trying anymore
    if (!arrayOrStringWithCommas) {
      return [''];
    }
    // If this isn't an array it's either a number or string or a commaseparated string
    if (!Array.isArray(arrayOrStringWithCommas)) {
      if (typeof arrayOrStringWithCommas === 'string') {
        if (!arrayOrStringWithCommas.includes(',')) {
          // If it's not a commaseparated string it's just one ID , so return it as array of 1
          return [this._scrubString(arrayOrStringWithCommas)];
        } else {
          // Otherwise split by comma and return array
          return this._scrubString(arrayOrStringWithCommas).split(',');
        }
      }
      // This is neither an array nor string so it has to be a simple number. Just return it as array of one string
      else return [arrayOrStringWithCommas.toString()];
    }
    // At this point it has to be either an array of strings or array of numbers so just return as array of string
    return arrayOrStringWithCommas.map(o => (o ? this._scrubString(o.toString()) : ''));
  }
  static isEmpty<T>(obj: T) {
    return JSON.stringify(obj) === '{}';
  }
  static nullIfEmpty<T>(obj: T): T | null {
    return JSON.stringify(obj) === '{}' ? null : obj;
  }
  static isNewObjectId(id: SafariObjectId) {
    if (!id) {
      return true;
    }
    return SafariObject._idToString(id).includes('-');
  }
  /**
   * This function converts ID to either a comma separated string (if this was an array of IDs)
   * OR just returns the string that was passed in
   * @param arrayOrStringWithCommas Can be '12345' or '123443,23232' or [1232342] or [1233242,12323] or ['1243243', 122432]
   *                                and so forth. But it has to be either a string or number or an array of strings and numbers
   * @returns
   *
   * If you pass in '123' , returns '123'
   * If you pass in 123 , returns '123'
   * If you pass in ['123','456'] returns '123,456'
   * If you pass in [123,456] returns '123,456'
   * If you pass in '123,456' returns '123,456'
   */
  private static _idToString(arrayOrStringWithCommas: string[] | number[] | string | number): string {
    const mapNulls = a => {
      if (a === null) {
        return ''; //null;
      }
      if (a === undefined) {
        return ''; //undefined;
      }
      if (typeof a === 'string') {
        return '';
      }
      if (typeof a === 'number') {
        return '0';
      }
      throw new Error('Unsupported type in SafariObjectId');
    };
    if (!arrayOrStringWithCommas) {
      return mapNulls(arrayOrStringWithCommas);
    }
    if (typeof arrayOrStringWithCommas === 'string') {
      return this._scrubString(arrayOrStringWithCommas);
    }
    if (typeof arrayOrStringWithCommas === 'number') {
      return this._scrubString(arrayOrStringWithCommas.toString());
    }
    if (Array.isArray(arrayOrStringWithCommas)) {
      return arrayOrStringWithCommas.map(o => SafariObject._idToString(o)).join(',');
    }
    throw new Error('Unsupported type in SafariObjectId');
  }
  static id(...args): string {
    if (args.length == 1) {
      return SafariObject._idToString(args[0]);
    }
    const id1 = args[0];
    const id2 = args[1];
    let result = SafariObject.NOID;
    if (id1 == null && id2 == null) {
      result = SafariObject.NOID + ',' + SafariObject.NOID;
    } else if (id1 == null) {
      result = SafariObject.NOID + ',' + SafariObject._idToString(id2);
    } else if (id2 == null) {
      result = SafariObject._idToString(id1) + ',' + SafariObject.NOID;
    } else {
      // Now that we've done basic sanity check let's concat these two
      const parentId = SafariObject.idToArray(id1);

      const childId = SafariObject.idToArray(id2);

      let index = -1;
      for (let i = childId.length - 1; i >= 0; i--) {
        // Let's see if we find any subIDs from childId in parentId
        // (for example - concatinating '1,2,3,4' with '3,4,5')
        index = parentId.findIndex(x => SafariObject.idEqual(x, childId[i]));
        if (index != -1) {
          // console.log('found same value for ', childId[i]);
          // console.log('child index', i)
          // console.log('parent index', index)
          let testParentIndex = index;
          // We found an overlap between two arrays. Now we have to do a sanity check.
          for (let z = i; z >= 0; z--) {
            // console.log('testIndex', testParentIndex);
            // console.log('z', z)
            if (!SafariObject.idEqual(parentId[testParentIndex], childId[z])) {
              // console.log('parent value' ,  parentId[testParentIndex]);
              // console.log('child value', childId[z])
              throw new Error('Unuequal IDs with similar hierarchy. ParentId: ' + SafariObject._idToString(parentId) + ', childId: ' + SafariObject._idToString(childId));
            }
            testParentIndex--;
          }
          //break;
        }
      }
      const finalParentArray = parentId.slice(0, index == -1 ? parentId.length : index);
      result = SafariObject._idToString([...finalParentArray, ...childId]);
    }
    if (args.length > 2) {
      result = SafariObject.id(result, ...args.slice(2));
    }
    return result;
  }
  /**
   *
   * @param a If ID was jsonifyed it might have " in it so just replace those
   * @returns
   */
  private static _scrubString(a): string {
    // Replace anything that is not a number or a comma and then strip any trailing commas
    return a.replace(/[^\d\w,-]+/g, ''); //.replace(/,\s*$/, "");
  }
  private static _arrayToNonArrayCompare(array: string, notArray: string) {
    return SafariObject.idObjectOnly(array) == notArray;
  }
  static idEqual(a, b): boolean {
    // Let's first convert these two to string.
    // This will make them follow the exact same format and be very easy to compare
    const obj1 = SafariObject._idToString(a);
    const obj2 = SafariObject._idToString(b);

    // Now let's check for a special case of one not being a nested ID and the other one just a basic number/string
    // This is likely to happen especially during early conversion where we still don't have mappers automapping
    // child/parent relationships, etc. In this case we can just compare the last ID in the array with the number/string
    if (SafariObject.idIsCompound(a) && !SafariObject.idIsCompound(b)) {
      return SafariObject._arrayToNonArrayCompare(a, b);
    } else if (SafariObject.idIsCompound(b) && !SafariObject.idIsCompound(a)) {
      return SafariObject._arrayToNonArrayCompare(b, a);
    }
    return obj1 == obj2;
  }
  /**
   *
   * @param id objectId - can be string, number or array of strings or numbers
   * @returns Always returns the objectID only (last item in the array, or itself if not array)
   *
   * If you pass in '123' , returns '123'
   * If you pass in 123 , returns '123'
   * If you pass in ['123','456'] returns '456'
   * If you pass in [123,456] returns '456'
   * If you pass in '123,456' returns '456'
   *
   */
  static idObjectOnly(id: StringOrNumber): string {
    if (!SafariObject.idIsCompound(id)) {
      return SafariObject._idToString(id);
    } else {
      const allIds = [...SafariObject.idToArray(id)];
      const lastElementId = allIds.pop();
      return lastElementId;
    }
  }
  /**
   *
   * @param id objectId - can be string, number or array of strings or numbers
   * @returns Always returns object's parents only (everything except last item in the array, or [] if not array)
   *
   * If you pass in '123' , returns []
   * If you pass in 123 , returns []
   * If you pass in ['123','456', '789'] returns ['123', '456']
   * If you pass in [123, 456, 789] returns ['123', '456']
   * If you pass in '123,456, 789'  returns ['123', '456']
   *
   */
  static idParentsOnly(id: StringOrNumber) {
    if (!SafariObject.idIsCompound(id)) {
      return [];
    } else {
      const allIds = [...SafariObject.idToArray(id)];
      allIds.pop();
      return allIds;
    }
  }
  /**
   * This looks like it should be in crud service but we don't want it there. Some UI code might
   * sometimes want to parse an endpoint (like for example to get a public URL for a file or something like that)
   * And we definitely don't want UI to import crud.service.ts. So we'll keep this here and we'll
   * eventually repoint crud.service to use this (we need to fully get rid of __parentIds before we can do that )
   * @param id ID of the object
   * @param endpoint endpoint in our standard form such as /parent/{0}/child{1}/child2 etc
   * @returns Final endpoint with correct substitutions
   */
  static formatEndpoint(id: StringOrNumber, endpoint: string, useObjectId = true): string {
    let objectAndParents: {
      objectId: string;
      parentIds: string[];
    } = null;
    if (!SafariObject.idIsCompound(id)) {
      objectAndParents = {
        objectId: SafariObject._idToString(id),
        parentIds: []
      };
    } else {
      const parentIds = [...SafariObject.idToArray(id)];
      const lastElementId = parentIds.pop() || null;

      objectAndParents = {
        objectId: lastElementId,
        parentIds: parentIds || []
      };
    }

    for (let i = 0; i < objectAndParents.parentIds.length; i++) {
      endpoint = endpoint.replace('{' + i.toString() + '}', objectAndParents.parentIds[i]);
    }
    if (useObjectId) {
      return (endpoint + '/' + (objectAndParents.objectId || '')).replace(/\/+$/, '');
    } else {
      return (endpoint + '/').replace(/\/+$/, '');
    }
  }
  /**
   * If what's passed in is an array or a comma separated string
   *
   * @param id
   * @returns
   */
  static idIsCompound(id: string | number | string[] | number[]) {
    if (id == null) {
      return false;
    }
    if (typeof id === 'number') {
      return false;
    }
    if (Array.isArray(id)) {
      return true;
    }
    return id.includes(',');
  }
}
export class SafariBulkObject<T> extends SafariObject {
  objects: T[];
}
